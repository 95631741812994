@use "@wojo/ui/mixins" as *;

.container {
    padding: var(--g-spacing-lg) 0;

    @include breakpoint-lg {
        padding: var(--g-spacing-md) 0;
    }
}

.main-nav {
    align-items: flex-end;
    margin-bottom: var(--g-spacing-md);
    justify-content: space-between;

    @include breakpoint-lg {
        margin-bottom: 0;
    }
}
.main-nav-mobile {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: var(--g-spacing-md);
    justify-content: space-between;

    @include breakpoint-lg {
        margin-bottom: 0;
    }
}
.logo-link {
    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--c-header-link-outline-border-radius-focus);
    }
}

.logo {
    display: flex;
    img {
        height: 20px;
        width: auto;

        @include breakpoint-lg {
            height: 34px;
        }
    }
}
