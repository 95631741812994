.expanded {
    transition: transform var(--g-duration-xshort) ease-in-out;
    transform: rotate(180deg);
}

.collapsed {
    transition: transform var(--g-duration-xshort) ease-in-out;
    transform: rotate(0);
}

.dropdown {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--s-color-surface-secondary);
    padding-top: var(--g-spacing-xl);
    padding-bottom: var(--g-spacing-md);
    box-shadow: var(--s-shadow-down);
    z-index: 3;
    display: flex;
}

.dropdown-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--g-spacing-xl);
}

.dropdown-column {
    flex: var(--grid-columns);
    height: fit-content;
    padding-bottom: var(--g-spacing-md);
}

.column-heading {
    padding-bottom: var(--g-spacing-md);
}

.column-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: var(--g-spacing-md);
}

.dropdown-button {
    flex: 0 0 100%;
    margin-top: var(--g-spacing-md);
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
    padding-top: var(--g-spacing-md);
    display: flex;
    justify-content: center;
}

.link {
    font-family: var(--g-font-family-primary);
    font-weight: var(--g-font-weight-regular);
    font-size: var(--g-font-size-400);
    line-height: var(--g-line-height-400);
    color: var(--s-color-text-primary);
    text-decoration: unset;
    width: fit-content;

    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;

        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--s-border-radius-interactive-focus);
    }

    &:hover,
    &:active {
        transition: ease var(--g-duration-xshort);
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;
    }
}
