.expanded {
    background-color: var(--s-color-surface-primary);
}

.links-wrapper {
    list-style-type: none;
    padding: 0;
    display: grid;
    gap: var(--g-spacing-md);

    &--no-title {
        gap: 0;
        > li {
            margin-bottom: var(--g-spacing-md);
        }
    }
}

.link {
    text-decoration: none;
    color: var(--s-color-text-primary);

    &:visited {
        color: inherit;
    }

    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--s-border-radius-interactive-focus);
    }

    &:hover,
    &:active {
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;
        transition: ease var(--g-duration-xshort);
    }
}
