@use "@wojo/ui/mixins" as *;

.nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.list-item {
    display: flex;
    flex-direction: column;
    padding: var(--g-spacing-md) var(--g-spacing-lg);
    border-top: solid;
    border-color: var(--s-color-separator-standard) transparent;
    border-width: var(--g-border-width-xs);

    @include breakpoint-md {
        padding-left: var(--g-spacing-xl);
        padding-right: var(--g-spacing-xl);
    }
}

.link {
    text-decoration: none;
    color: var(--s-color-text-primary);

    &:visited {
        color: inherit;
    }

    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--s-border-radius-interactive-focus);
    }

    &:hover,
    &:active {
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;
        transition: ease var(--g-duration-xshort);
    }

    &--login {
        text-decoration: none;
        font-weight: var(--g-font-weight-bold);

        &:focus-visible {
            text-decoration: underline;
            text-underline-offset: var(
                --c-button-quiet-small-underline-spacing
            );
        }

        &:hover,
        &:active {
            text-decoration: underline;
            text-underline-offset: var(
                --c-button-quiet-small-underline-spacing
            );
        }

        &--sign-out {
            font-weight: var(--g-font-weight-regular);

            &:focus-visible {
                text-decoration: underline;
                text-underline-offset: var(
                    --c-button-quiet-small-underline-spacing
                );
            }

            &:hover,
            &:active {
                text-decoration: underline;
                text-underline-offset: var(
                    --c-button-quiet-small-underline-spacing
                );
            }
        }
    }
}

.banner-section {
    display: flex;
    flex-direction: column;
    padding: var(--g-spacing-lg);
    border-top: solid;
    border-color: var(--s-color-separator-standard) transparent;
    border-width: var(--g-border-width-xs);

    @include breakpoint-md {
        padding-left: var(--g-spacing-xl);
        padding-right: var(--g-spacing-xl);
    }
}
