@use "@wojo/ui/mixins" as *;

.desktop {
    display: none;

    @include breakpoint-lg {
        display: flex;
    }
}

.nav-wrapper {
    width: 100%;
    padding-left: var(--g-spacing-lg);
}

.nav-bottom {
    display: flex;
    gap: var(--g-spacing-md);
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.search {
    justify-self: end;
    max-width: 100%;
    width: 180px;
    @include breakpoint-xl {
        width: 311px;
    }
}
.desktop-megamenu {
    justify-self: center;
}
