.item {
    cursor: pointer;
    text-decoration: none;
    padding: 0;
    margin: 0;

    .title {
        display: flex;
        font-family: var(--g-font-family-primary);
        font-size: var(--c-header-main-nav-link-font-size);
        font-weight: var(--c-header-main-nav-link-font-weight);
        letter-spacing: var(--c-header-main-nav-link-letter-spacing);
        line-height: var(--g-line-height-1100);
        color: var(--s-color-text-primary);
        transition: color ease var(--g-duration-xshort);
        align-self: center;
        text-transform: var(--c-header-main-nav-link-text-case);

        &:focus-visible {
            outline-color: currentColor;
            outline-width: var(--g-border-width-sm);
            outline-offset: var(--g-spacing-xxs);
            outline-style: solid;
            border-radius: var(--s-border-radius-interactive-focus);

            transition: none;
            color: var(--s-color-brand-primary-standard);
        }
    }

    .icon {
        color: var(--s-color-icon-primary);
        padding-left: var(--g-spacing-xs);
        padding-top: var(--c-header-main-nav-icon-spacing-top);
        transition: color ease var(--g-duration-xshort);
    }

    &:focus-visible {
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--s-border-radius-interactive-focus);

        .title {
            transition: none;
            color: var(--s-color-brand-primary-standard);
        }

        .icon {
            transition: none;
            color: var(--s-color-brand-primary-standard);
        }
    }

    &:hover,
    &:active {
        .title {
            color: var(--s-color-brand-primary-standard);
        }

        .icon {
            color: var(--s-color-brand-primary-standard);
        }
    }
}
